@import "filepond.min";


$green1:          #33cb24;
$green2:          #7bc373;
$green3:          #2ecd99;
.swal2-container {
  z-index: 10001 !important;
}
.capitalize {
  text-transform: capitalize;
}
.display-6 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.1;
}
.logo-config {
  border:1px solid #f2f2f2;
  display: block;
  margin: 0 auto;
}
.bg-gray {
  background-color: lighten($gray-300, 4%);
}
.pd-2 {
  padding: 20px;
}
.green {
  color: $green1;
}
.record-img {
  width: 200px;
  height: 120px;
  background-position: center;
  background-size: cover;
  display: block;
  border-radius: .25rem;
  overflow: hidden;
  border: 3px solid #fff;
  -webkit-box-shadow: 1px 1px 1px rgba(0,0,0,.2);
  box-shadow: 0px 1px 2px rgba(0,0,0,.2);
  margin: 5px;
  display: inline-block;
}
.min-h {
  min-height: 240px;
}
.signature-pad {
  border: 1px solid #dee2e6;
  border-radius: .2rem;
  width: 100%;
  height: 150px;
}
.hide {
  display: none;
}
.hideloader {
    display: none;
}
.success-gradient {
  background: linear-gradient(135deg,#8BC34A,#4CAF50) !important; 
}
.danger-gradient {
  background: linear-gradient(135deg,#F44336,#E91E63) !important;
}
#loader {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: $green3;
    z-index: 99999;
} 
#saving-loader {
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color: rgba(0,0,0,0.5);
  z-index: 9999;
}
.sk-chase {
    width: 40px;
    height: 40px;
    position: absolute;
    animation: sk-chase 2.5s infinite linear both;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin: auto;
  }
  
  .sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; 
    animation: sk-chase-dot 2.0s infinite ease-in-out both; 
  }
  
  .sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #fff;
    border-radius: 100%;
    animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
  }
  
  .sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
  .sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }
  
  @keyframes sk-chase {
    100% { transform: rotate(360deg); } 
  }
  
  @keyframes sk-chase-dot {
    80%, 100% { transform: rotate(360deg); } 
  }
  
  @keyframes sk-chase-dot-before {
    50% {
      transform: scale(0.4); 
    } 100%, 0% {
      transform: scale(1.0); 
    } 
  }

.dd-hasparent {
  padding-left: 40px;
}
.dd-ctrls {
  float: right;
}
.dd-collapsed .form-fields {
  display: none;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #ccc;
}

@keyframes placeHolderShimmer {
  0% {
      background-position: 0 0;
  }

  100% {
      background-position: 10000px 0;
  }
} 

.loading-text {
  animation-duration:30s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeHolderShimmer;
  width: 100%;
  background: #f6f7f8;
  background: linear-gradient(to right, #f9f9f9 8%, #f2f2f2 18%, #f9f9f9 33%);
  height: 20px;
  position: relative;
}
.print-btn-container {
  position: fixed;
  top:20px;
  right: 20px;
  z-index: 99;
}
.badge-largue {
  font-size: 12px;
}
.separator {
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 30px;
}
.pdf-page {
  background-color: #525659;
}
.public-page {
  background-color: #ccc;
}
.content-public {
  max-width: 900px;
  margin: 0 auto;
  color: #000;
}
.content-pdf {
  max-width: 900px;
  margin: 0 auto;
  color: #000;
}
.pdf-logo {
  max-height: 50px;
  width: 100%;
  img {
    margin: auto;
    display: block;
    width: auto;
    height: 100%;
  }
}
.pdf-logo-col {
  overflow: hidden;
  min-height: 50px;
  .pdf-logo-wrapper {
    width: 500px;
    height: 500px;
    position: absolute;
    top:0;
    left: 0;
    background-color: #ccc;
  }
}
.pdf-block {
   
}
.pdf-unit-content {
  padding: 10px;
  border: 1px solid #e6e6e6;
 
}
.pdf-fields {
  padding: 0;
  margin: 0 auto;
  max-width: 400px;
  width: auto;
  p {
    margin:0;
  }

  li:nth-child(even) {
    background-color: #f2f2f2;
  }
  li {
    list-style-type: none; 
    width: 100%;
    clear: both;
    padding: 5px;
  }
   
}
.pdf-images { 
  img {
    max-width: 100%;
  }
  .noprint {
    visibility: hidden;
  }
}

@media print {
  .pagebreak {
      clear: both; 
      page-break-before: always
  }
  .noprint {
    visibility: hidden;
 }
  .content-pdf {
    max-width: 100%;
  }
}
.pdf-main-data {
  
  p {
    color: #000;
    margin: 0 0 10px;
    font-size: 1.3em;
    span {
      float: right;
    }
  }
}
.asset-field-required {
  label {
    color: #f00;
  }
}
.assets-collection-results {
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style-type: none;
    margin-bottom: 5px;
  }
  min-height: 20px;
  max-height: 100px;
  overflow-y: scroll;
  border: 1px solid #e4e4e4;
  border-radius: 3px;
  padding: 10px;
  margin: 0;

}
#qrcode img {
  display: 'block';
  margin: 0 auto;
}
.slimscroll-menu {
  overflow-y: auto !important;
}

#spreadsheet {
  .jexcel_selectall {
    max-width: 50px;
  }
  table.jexcel {
    width: 100%;
  }
}
.modal-xxl {
  max-width: 98%;
  width: 100%;
}
.email_preview_iframe {
  width: 100%;
  border:none;
}
.c3-chart-arc text {
  fill:#fff;
}

.dd-collapsed .dd3-content {
  background: #d6d6d6 !important;
}

.dd-list .dd3-content:hover {
  color: inherit;
}
.dd-list .dd3-content label:hover {
  color: $green1;
}
.dd3-content {
  .form-group label {
    font-weight: bold;
  }
}
.collection-option-wrapper {
  position: relative;
  z-index: 9999;
  width: 100%;
}
.collection-option-list {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fbfbfb;
  border-radius: 3px;
  border-top: none;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow: auto;
  width: 100%;
  li {
    list-style-type: none;
    padding: 7px;
  }
}
.clear-collection-btn {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: 0;
  margin: auto;
}
.modal.fade.show {
  z-index: 10000;
}
.modal-backdrop {
  z-index: 9999;
}
.divider {
  margin-bottom: 25px;
  border-bottom: 1px solid #f2f2f2;
}

.select2-container * {
  z-index: 100001 !important;
} 